
#max{
    padding: 5%;
}

#fax{
    margin-top: 5%;
    padding: 5%;
}

#ftl{
    padding: 5%;
   
}

#xrp{
    padding: 5%;
    background-color: #777;
}
#xt{
    margin-top: 2%;
    margin-bottom: 5%;
}
#rt{
    margin-left: 1%;
}
#inlineFormInputName{
    margin-top: 2%;
}
#lit{
    margin-top: 2%;
}
.dxt{
    height: 10%;
}
#rw{
    margin-top: 2%;
}

#kpr{
    padding: 5%;
}