#main{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(173, 181, 189)
}
#text{
    color: #777;
}

#margin-text{
    margin-top: 2%;
    text-align: center;
    margin-bottom: 2%;
}

.justify-content-md-center{
 padding: 5%;
}

#margin-a{
    margin-top: 2%;
    width: 40%;
    margin-left: 35%;
    color: #777;
    text-align: center;
    
}

#dep-withd-row{
  
}

#ms {
    color: blue;
    width: 80%;
    padding: 10%;
    margin-left: auto;
    margin-right: auto;
        
      }

#margin-text2{
    margin-top: 10%;
    font-weight: bold;
    
}



#margin-b{
   
    text-align: center;
}

#margin-c{
    margin: auto;
    color: #777;
    font-size:14px
}

