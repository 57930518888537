
.App {
  text-align: center;
}

/*overall div holding body*/
.main{
  font-family: "Open Sans", Arial, sans-serif;
}

