
#ftx{
    padding: 5%;
}

#rftc{
    padding: 2%;
    background-color: aquamarine;
}
#tr{
    margin-left: 8%;
}
#btr1{
   
}
#rftd{
    margin-top: 4%;
    margin-left: 9%;
}

#rfte{
    margin-top: 2%;
    padding-left: 10%;
    padding-right: 10%;
    color: #777;
    text-align: justify;
}
#tf{

    margin-left: 5%;
}

#rftg{
    
    padding-left: 2%;
    padding-right: 2%;
}

#btr4{
    margin-left: 2%;
}

#lrow{
    padding: 5%;
    background-color: aliceblue;
   
    margin-top: 2%;
    margin-bottom: 2%;
    border-radius: 5px;
    border-style: solid;
    border-color: burlywood;
    border-width: 6px;
    color: #777;
}
#mgx{
    margin-top: 2%;  
}