
#frd{
    padding: 5%;
    background-color: beige;
}


#fta{
    font-weight: 1000;
    
}

#ftb{
    font-weight: 1000;
}

#ftd{
    margin-top: 5%;
    text-align: justify;
}
#ftg{
    margin-top: 5%;
    text-align: center;
}

#fcd{
    border-bottom:2px solid red;
}

#npq{
    left: 7%;
}

