#form-container{
  padding-top:7%;
}
#subdiv{
margin: auto;
}
#footer-text{
    color: #777;
  margin: auto;
    text-align: center;
    font-size:14px
}

