#form-container{
  padding-top:15%;
  
}
#fs {
  margin: auto;
    
  }
  
  #footer-text{
    margin: auto;
    color: #777;
    font-size:14px
}

#bnav{
  margin: auto;
}

