#fax{
    padding : 5%;
    background-color: #78807A;
    color: white;
}

#lox{
    padding : 6%;
    color: white;
}

#faxd{
    margin-left: 12%;
}

#faxe{
    
    background-color: greenyellow;
    color: white;
}

#faxee{
    
    margin-left: 12%;
}

#raq{
    margin-top: 2%;
    color: #777;
    margin-bottom: 2%;
}

#rex{
    padding: 10%;
    background-color:limegreen;
    text-align: center;
    color: green;
    border-radius: 2%;
}

#tdr{
   color: white; 
}

#dfg{
    margin-top: 0.5%;
   
    
}

.accordion-header{
    color: white;
}



 

#hox{
    margin-top: 5%;
}

#ekk{
    background-color: #777;
    color: white;
}

.accordion-button {
    background-color: gray !important;
    color: white   !important ;
    border-radius: 40%;
  }
  
 